import { Navigate } from "react-router-dom";
import {signOut} from '../network/auth';

const Signout = () => {
    signOut();

    return (
        <Navigate to="/home" replace={true} />
    );
}

export default Signout;