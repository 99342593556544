import {useState} from 'react';
import {register} from '../network/auth';
import TermsConditionsModal from '../components/modals/TermsConditionsModal';

const RegistrationForm = () => {
    const [show,setShow] = useState(false);
    const [email,setEmail] = useState('');
    const [password,setPass] = useState('');
    const [passCheck,setPassCheck] = useState('');
    const [name, setName] = useState('');
    const [gender, setGender] = useState('M');
    const [emailErrorClass, setEmailErrorClass] = useState('');
    const [passwordErrorClass, setPasswordErrorClass] = useState('');
    const [nameErrorClass, setNameErrorClass] = useState('');
    const [formErrors, setFormErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');


    const validateForm = () => {
        //clear
        setFormErrors([]);
        setEmailErrorClass('');
        setPasswordErrorClass('');
        setNameErrorClass('');

        let emailRegex = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])/
        let passRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/
        let valid = true;
        if(name.trim() === '' || name.trim().length < 6) {
            setFormErrors(['You must enter a value of more than six characters.',]);
            setNameErrorClass('formErrorInput');
            document.querySelector('#name').focus();
            valid = false
        }
        if(email.trim() === '') {
            setFormErrors(['You must enter a value.',]);
            setEmailErrorClass('formErrorInput');
            document.querySelector('#email').focus();
            valid = false
        }
        if(!email.trim().match(emailRegex)) { 
            setFormErrors(['Invalid email address.',]);            
            setEmailErrorClass('formErrorInput');
            document.querySelector('#email').focus();
            valid = false;
        }
        if(password.trim() === '') {
            setFormErrors(['You must enter a value.',]);
            setPasswordErrorClass('formErrorInput');
            document.querySelector('#password').focus();
            valid = false
        }
        if(passCheck.trim() === '') {
            setFormErrors(['You must enter a value.',]);
            setPasswordErrorClass('formErrorInput');
            document.querySelector('#password').focus();
            valid = false
        }
        if(!password.trim().match(passRegex)) { 
            setFormErrors(['Pasword must be ten characters or more, contain at least one upper and one lower case character and contain one of the following characters "@$!%*#?&".',]);
            setPasswordErrorClass('formErrorInput');
            document.querySelector('#password').focus();
            valid = false;
        }
        if(!(password.trim() == passCheck.trim())) { 
            setFormErrors(['Paswords do not match.',]);
            setPasswordErrorClass('formErrorInput');
            document.querySelector('#password').focus();
            valid = false;
        }
        return valid;
    }

    const handleRegistration = async (e) => {
        e.preventDefault();
        const payload = {
            email,
            password,
            passchk : passCheck,
            username : name,
            gender : gender
        }
        if(validateForm()) {
            const success = await register(payload);
            if(success) {
                setSuccessMessage('You have successfully registered. Please check your email and click on the confirmation link to activate your account.');
            } else {
                setFormErrors(['An error occured during registration.']);
            }
        }
    }

    const handleChangePass = (e) => {
        setPass(e.target.value);
    }

    const handleChangePassCheck = (e) => {
        setPassCheck(e.target.value);
    }
    
    const handleChangeName = (e) => {
        setName(e.target.value);
    }
    
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleChangeGender = (e) => {
        setGender(e.target.value);
    }

    const handleLaunchTerms = (e) => {
        e.preventDefault();
        setShow(true);
    }

    return(
        <>
            <div className="modal-container">
                <TermsConditionsModal
                    show={show}
                    closeAction={() => setShow(false)} 
                />
            </div>
            {
                <form id="signInForm" className="userForm" onSubmit={handleRegistration} >
                    <div className="form-group">
                        <label htmlFor="username">User Name:</label>
                        <input id="username" className={nameErrorClass} type="text" onChange={handleChangeName} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input id="email" className={emailErrorClass} type="email" onChange={handleChangeEmail} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input id="password" className={passwordErrorClass} type="password" onChange={handleChangePass} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="passcheck">Confirm:</label>
                        <input id="passcheck" className={passwordErrorClass} type="password" onChange={handleChangePassCheck} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="gender">Gender:</label>
                        <select id="gender" onChange={handleChangeGender}>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                    </div>
                    <label htmlFor="terms">
                        <input type="checkbox" required={true}/>
                        I agree, to the <a href="" onClick={(e) => handleLaunchTerms(e)}>terms and conditions</a>.
                    </label>
                    <div className="form-submit">
                        <input type="submit" value="Register" />
                    </div>
                    <div className="form-errors">
                        <ul>
                            {
                                formErrors.map(e => (
                                    <li key={e}>{e}</li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="success-message-container"><p>{successMessage}</p></div>
                </form>
            }
        </>

    );
}

export default RegistrationForm;