import {useNavigate} from "react-router-dom"
import {useState, useContext} from 'react';
import {authenticate} from '../network/auth';
import {Context} from '../context/StorageContext';

const SignInForm = () => {
    const navigate = useNavigate();
    const {setLoggedIn} = useContext(Context);

    const [email,setEmail] = useState('');
    const [password,setPass] = useState('');
    const [emailErrorClass, setEmailErrorClass] = useState('');
    const [passwordErrorClass, setPasswordErrorClass] = useState('');
    const [formErrors, setFormErrors] = useState([]);


    const validateForm = () => {
        //clear
        setFormErrors([]);
        setEmailErrorClass('');
        setPasswordErrorClass('');
        let valid = true;
        if(email.trim() === '') {
            setFormErrors(['You must enter a value.',]);
            setEmailErrorClass('formErrorInput');
            document.querySelector('#email').focus();
            valid = false
        }
        if(password.trim() === '') {
            setFormErrors(['You must enter a value.',]);
            setPasswordErrorClass('formErrorInput');
            document.querySelector('#password').focus();
            valid = false
        }
        return valid;
    }

    const handleSignIn = async (e) => {
        e.preventDefault();
        const payload = {
            email,
            password
        }
        if(validateForm()) {
            const result = await authenticate(payload);
            console.log(result);
            if(result !== 'Success') {
                setFormErrors([result]);
            } else {
                setLoggedIn();
                navigate('/');
            }
        }
    }

    const handleChangePass = (e) => {
        setPass(e.target.value);
    }
    
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    }

    return(
        <>
            {
                <form id="signInForm" className="userForm" onSubmit={handleSignIn} >
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input id="email" className={emailErrorClass} type="email" onChange={handleChangeEmail} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input id="password" className={passwordErrorClass} type="password" onChange={handleChangePass} />
                    </div>
                    <div className="form-submit">
                        <input type="submit" value="Sign In" />
                    </div>
                    <div className="form-errors">
                        <ul>
                            {
                                formErrors.map(e => (
                                    <li key={e}>{e}</li>
                                ))
                            }
                        </ul>
                    </div>
                </form>
            }
        </>

    );
}

export default SignInForm;