import {useContext, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Context} from '../../context/StorageContext';

const CheckoutSuccess = () => {
    const {state:{selectedEvent}} = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        if(!selectedEvent) {    
            navigate('/home');
        }
    });


    return(
        <section id="checkoutsuccess" className="one-page">
            <div className="one-page-content message-content">
                <h1 className="center-text">Congratulations! You have successfully booked the event 
                    {selectedEvent.title} on {selectedEvent.start_date_local}.</h1>
                <p className="center-text">£{selectedEvent.price} will be charged to your card. You will receive a confirmation email shortly.</p>
            </div>
        </section>
    );
}

export default CheckoutSuccess;