import {useState, useEffect, useContext} from 'react';
import {checkAuthed} from '../network/auth';
import {Context} from '../context/StorageContext';

const LayoutHeader = () => {
    const [isAuthed, setIsAuthed] = useState(false);
    const {state:{loggedIn}} = useContext(Context);

    useEffect(() => {
        const checkAuthentication = async () => {
            let authed = await checkAuthed();
            if(authed) {
                setIsAuthed(true);
            };
        }
        if(loggedIn) {
            setIsAuthed(true);
        } else {
            checkAuthentication();
        }
    },[]);

    return(
        <div className="navbar">
            <div className="navbar-logo">
                DATERADOS  
            </div>
            {
                isAuthed || loggedIn
                ? <nav>
                    <ul>    
                        <li><a href="/home">Home</a></li>
                        <li><a href="/events">Events</a></li>
                        <li><a href="/profile">Profile</a></li>
                        <li><a href="/signout">Sign Out</a></li>
                    </ul>
                </nav>
                : <nav>
                    <ul>
                        <li><a href="/home">Home</a></li>
                        <li><a href="/events">Events</a></li>
                        <li><a href="/signin">Signin</a></li>
                        <li><a href="/register">Register</a></li>
                    </ul>
                </nav>
            }

        </div>
    )

}

export default LayoutHeader;