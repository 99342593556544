import decode from 'jwt-decode';
import {conn} from './api';

const getAccessToken = () => {
  const accessToken = localStorage.getItem('access_token');
  if(accessToken) {
     return decode(accessToken);
  } else {
      return null;
  }
}


const checkAuthed = () => {
  return new Promise((resolve,reject) => {
    const accessToken = localStorage.getItem('access_token');
    if(accessToken) {
      const decoded = decode(accessToken);
      const tokenExpiry = new Date(decoded.exp*1000);
      const now = new Date();
      if(now < tokenExpiry) {
            //Hasn't expired
            return resolve(true);
      } else {
            //Try refresh
            const refreshToken = localStorage.getItem('refresh_token');
            if(refreshToken)
            {
                conn.post('/accounts/refresh/',{ refresh: refreshToken }).then((res) => {
                    localStorage.setItem('access_token',res.data.access);
                    return resolve(true);
                }).catch((err) => {
                    return resolve(false);
                });
            } else {
                return resolve(false);
            }
      }
    } else {
        //Token doesn't exist
        return resolve(false);
    }
  });
}


const authenticate =  payload => {
  return new Promise((resolve,reject) => {
      conn.post('/accounts/authenticate/',payload)
      .then( (response) => {
        localStorage.setItem('access_token',response.data.access);
        localStorage.setItem('refresh_token',response.data.refresh);
        return resolve('Success');
      }).catch((err) => {
        console.log(err);
        switch (err.response.status) {
          case 401:
            return resolve(err.response.data.detail);
          default:
            return resolve('An error occured');
        }
      });
  });
}

const register =  payload => {
    return new Promise((resolve,reject) => {
        conn.post('/accounts/register/',payload)
        .then( (response) => {
          return resolve(true);
        }).catch((err) => {
          return resolve(false);
        });
    });
  }

const signOut = async () => {
    //Detroy the tokens
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
}

const getUserId = () => {
  const accessToken = localStorage.getItem('access_token');
  if(accessToken) {
    const decoded = decode(accessToken);
    return decoded.user_id;
  } else {
    return null;
  }
}


const getGender = () => {
  const accessToken = localStorage.getItem('access_token');
  if(accessToken) {
    const decoded = decode(accessToken);
    return decoded.gender;
  } else  {
    return null;
  }
}

const resetPassword = (payload) => {
  return new Promise((resolve,reject) => {
    conn.post('/accounts/forgotpassword/',payload)
    .then( (response) => {
      return resolve(response);
    }).catch((err) => {
      return resolve(err);
    });
});
}

export {checkAuthed, authenticate, register, signOut, getAccessToken, getUserId, 
  resetPassword, getGender};