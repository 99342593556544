import { useContext, useState } from 'react';
import {Context} from '../context/StorageContext';
import BinaryModal from '../components/modals/BinaryModal';
import { submitLikes } from '../network/apiactions';

const AttendeesActionBtn = (props) => {
    const {setLike,state:{likeList}} = useContext(Context);
    const [showModal, setShowModal] = useState(false);

    const handleSubmitLikes = async () => {
        let payload = {
            eventId : props.event.id,
            likes : likeList
        }
        setShowModal(false);
        let success = await submitLikes(payload);
        if(success.status === 200) {
            //Force page refresh
            window.location.href = `/attendees/${props.event.id}`;
        } else {
            props.setSubmitMessage('Something went wrong submitting your choices.');
        }
    }


    return(
        <div>
            <div className="modal-container">
                <BinaryModal 
                    show={showModal} 
                    title="Are you Sure" 
                    message="Are you sure you want to submit your likes? Once this is done, you will not be able to change them." 
                    noAction={() => setShowModal(false)}
                    yesAction={handleSubmitLikes}
                />
            </div>
            {
                props.event.attending
                ? <> {
                    props.event.past_event
                    ? <>{
                        props.event.likes_submitted
                        ? <div className="noplaces-container"><p className="noplaces-text">Likes Submitted</p></div>
                        : <button type="button" className="flat-button" onClick={() => setShowModal(true)} >Send Likes</button>
                    }</>
                    : null
                }</>
                : null
            }
        </div>
    );
}

export default AttendeesActionBtn;