
const BinaryModal = (props) => {

    if(props.show) {
        document.body.classList.add('modal-overlay');
    } else {
        document.body.classList.remove('modal-overlay');
    }

    return (
        props.show
        ? <div className="modal">
            <h1>{props.title}</h1>
            <p>{props.message}</p>
            <div className="btn-group">
                <button type="button" className="flat-button" onClick={props.yesAction} >Yes</button>
                <button type="button" className="flat-button" onClick={props.noAction} >No</button>
            </div>
        </div>
        :null
    );
}

export default BinaryModal;