import {useState, useContext, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {Context} from '../context/StorageContext';
import {getEvent} from '../network/apiactions';
import { checkAuthed } from '../network/auth';
import EventBookingBtn from '../components/EventBookingBtn';


const Event = () => {
    const {setSelectedEvent, state:{selectedEvent, loggedIn}} = useContext(Context);
    const [isAuthed, setIsAuthed] = useState(false);
    let { id } = useParams();

    useEffect(() => {
        const getSingleEvent = async (authed) => {
            try {
                let response = await getEvent(id,authed);
                if(response.status === 200) {
                    setSelectedEvent(response.data);
                } else {
                    console.log('An error occured.',response.status)
                }
            } catch(err) {
                console.log(err);
            }
        }

        const processGetEvent = (authed) => {
            if(!selectedEvent) {
                getSingleEvent(authed);
            }
            setIsAuthed(authed);
        }


        const checkAuthentication = async () => {
            let authed = await checkAuthed();
            if(authed) {
                processGetEvent(true);
            } else {
                processGetEvent(false);
            }
        }
    
        if(loggedIn) {
            processGetEvent(true);
        } else {
            checkAuthentication();
        }


    },[]);



    return(
        <section id="event">
            {
                selectedEvent
                ? <> 
                    <h1 className="section-heading"><span>{selectedEvent.title}</span></h1>
                    {
                        isAuthed && selectedEvent.attending
                        ? <>
                            {
                                selectedEvent.past_event
                                ? <p className="center-text">You attended this event, please click "view attendees" and submit your likes.</p>
                                : <p className="center-text">You are attending this event.</p>
                            }
                        </>
                            
                        : null
                    }
                    <div className="list-item">
                        <div className="card">
                            <div className="card-title">
                                <p>{selectedEvent.venue.name}</p>
                            </div>
                            <div className="card-image">
                                <img src={selectedEvent.venue.picture.image} className="contained-image" />
                            </div>
                            <div className="card-body">
                                <div className="map-link">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-map-fill" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z"/>
                                    </svg>
                                    <span>click 
                                    <a 
                                        href={`https://www.google.com/maps/place/${selectedEvent.venue.street_address.lat},${selectedEvent.venue.street_address.lng}`}
                                        target="_blank"
                                    > here</a> to view on google maps.</span>
                                </div>

                                <p><strong>Address</strong></p>
                                <div className="address-block">
                                    <p>{selectedEvent.venue.street_address.street}</p>
                                    <p>{selectedEvent.venue.street_address.town}</p>
                                    <p>{selectedEvent.venue.street_address.postcode}</p>
                                </div>
                                <p><strong>Directions</strong></p>
                                <p>{selectedEvent.venue.directions}</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title">
                                <p>Details</p>
                            </div>
                            <div className="card-body">
                                <div className="list-item">
                                    <p><strong>Start Date</strong></p>
                                    <p>{selectedEvent.start_date_local}</p>
                                </div>
                                <div className="list-item">
                                    <p><strong>Places Left</strong></p>
                                    <p>{selectedEvent.places.places_left}</p>
                                </div>
                                <div className="list-item">
                                    <p><strong>Males attending</strong></p>
                                    <p>{selectedEvent.places.males_attending}</p>
                                </div>
                                <div className="list-item">
                                    <p><strong>Females attending</strong></p>
                                    <p>{selectedEvent.places.females_attending}</p>
                                </div>
                                <div className="list-item">
                                    <p><strong>Price</strong></p>
                                    <p>£ {selectedEvent.price}</p>
                                </div>
                                <p><strong>Description</strong></p>
                                <p>{selectedEvent.description}</p>
                                <EventBookingBtn  authed={isAuthed} event={selectedEvent} />
                            </div>
                        </div>
                    </div>
                </>
                : <div><h1>No event is selected.</h1></div>
            }   

        </section>
    );
}

export default Event;