import { useNavigate } from "react-router-dom";
import { getGender } from "../network/auth";

const EventBookingBtn = (props) => {
    const navigate = useNavigate();

    return(
        <div>
            {
                props.authed
                ?   <> 
                        {
                            props.event.attending
                            ? <button type="button" className="flat-button" onClick={() => navigate(`/attendees/${props.event.id}`)} >View Attendees</button>
                            : <> {
                                props.event.can_join_event
                                ? <button type="button" className="flat-button" onClick={() => navigate(`/checkout/${props.event.Id}`)} >Book This Event</button>
                                : <div className="noplaces-container">
                                    {
                                        props.event.is_maxed_out
                                        ? <p className="noplaces-text">Sorry This Event Is Full</p>
                                        : <> { getGender() === 'F'
                                            ? <p className="noplaces-text">No Female Places</p>
                                            : <p className="noplaces-text">No Male Places</p>
                                        } 
                                        <p>Please try again later.</p> 
                                        </>
                                    } 
                                       
                                </div>

                                
                                
                                
                            } </>    
                        }
                    </>
                    
                : <button type="button" className="flat-button" onClick={() => navigate(`/signin`)} >Signin To Book</button>
            }
        </div>
    );
}

export default EventBookingBtn;