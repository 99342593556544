import {useState, useContext, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Context} from '../context/StorageContext';
import {getMyEvents} from '../network/apiactions';


const MyEvents = () => {
    const {setEventList,setSelectedEvent,state:{eventList}} = useContext(Context);
    const [loaded,setLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getEventList = async () => {
            let response = await getMyEvents();
            if(response.status === 200) {   
                setEventList(response.data);
                setLoaded(true);
            } else {
                console.log('An error occured');
            }
        }
        getEventList();
    },[]);


    const handleShowEvent = (e,event) => {
        e.preventDefault();
        let selectedEvent = eventList.filter(el => el.id === event.id);
        setSelectedEvent(selectedEvent[0]);
        navigate(`/event/${event.id}`);
    }

    return(
        <section id="events" className="scroll-page">
            <div>
                <h1 className="section-heading"><span>My Events</span></h1>

                <p className="center-text">These are the events you have booked.</p>
                {
                    loaded
                    ?   <>              {
                        eventList.length > 0
                        ? <div id='event-list' className="list-container">
                            <div className='list-grid list-head'>
                                <p>Event</p>
                                <p className="hide-mob">Starts on</p>
                                <p className="hide-mob">Ends at</p>
                                <p className="hide-mob">Location</p>
                                <p></p>
                            </div>
                            {
                                eventList.map((event) => (
                                    <div key={event.id} className='list-grid'>
                                        <p>{event.title}</p>
                                        <p className="hide-mob">{event.start_date_local}</p>
                                        <p className="hide-mob">{event.end_date_local}</p>
                                        <p className="hide-mob">{event.venue.name}</p>
                                        <p><a href="" onClick={ (e) => handleShowEvent(e,event)}>View</a></p>
                                    </div>
                                ))
                            }
                        </div>
                        : <p className="center-text">You have not yet booked any events! When you book events they will appear here.</p>
                    } </>

                    : <p>Loading</p>        
                }
            </div>
        </section>

    );
}

export default MyEvents;