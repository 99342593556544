import { Outlet, useLocation } from "react-router-dom";
import LayoutHeader from './layoutheader';

const Layout = () => {
    let location = useLocation();

    return(
        <>
            <header>
                <LayoutHeader />
            {
                location.pathname === '/' | location.pathname == '/home'
                ? <div className="hero">
                    <h1>DATERADOS</h1>
                    <h2>Speed Dating Events</h2>
                </div>
                : null
            }
            </header>
            <Outlet />
            <footer>
                <p>Daterados &copy; { new Date().getFullYear() }</p>
            </footer>
        </>
    )
}

export default Layout;