import { useContext } from 'react';
import portrait from '../assets/img/portrait.png';
import { Context } from '../context/StorageContext';
import { useNavigate } from 'react-router-dom';

const MatchListItem = (props) => {
    const {setMessageModalOptions,state:{messageModalOptions}} = useContext(Context);
    const navigate = useNavigate();

    const handleSendMessage = (e) => {
        e.preventDefault();
        setMessageModalOptions({...messageModalOptions,show:true,messageTo:{id:props.profile.id,name:props.profile.name}});
    }

    const handleViewProfile = (e,id) => {
        e.preventDefault();
        navigate(`/userprofile/${id}`);
    }


    return (
        
        <> 
        {
            props.profile.profile
            ? <div className="match-item match-profile">
                <div className="img-container"><img src={props.profile.profile.picture.image} alt={props.profile.name} /></div>
                <p>{props.profile.name}</p>
                <p><a href="" onClick={(e) => handleViewProfile(e, props.profile.profile.user.id)}>view profile</a></p>
                <p><a href="" onClick={(e) => handleSendMessage(e)}>message</a></p>
            </div>
            : <div className="match-item match-noprofile">
                <div className="img-container"><img src={portrait}  alt={props.profile.name} /></div>
                <p>{props.profile.name}</p>
                <p>This person doesn't have a profile.</p>
                <p><a href="" onClick={(e) => handleSendMessage(e)}>message</a></p>
            </div>
        } 
        </>
    );
}

export default MatchListItem;