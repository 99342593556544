const Error500 = () => {
    return(
        <section id="signin" className="one-page">
            <div className="one-page-content">
                <h1>Sorry an error has occured, the site may be down, please try again later.</h1>
            </div>
        </section>
    );
}

export default Error500;