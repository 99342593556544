
import {useState, useContext, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import AttendeeListItem from '../components/AttendeeListItem';
import {Context} from '../context/StorageContext';
import { getEvent } from '../network/apiactions';
import { getGender } from '../network/auth';
import AttendeesActionBtn from '../components/AttendeesActionButton';
import LoadingSpinner from '../components/LoadingSpinner';


const Attendees = () => {
    const {setSelectedEvent, state:{selectedEvent, loggedIn}} = useContext(Context);
    const [submitMessage, setSubmitMessage] = useState('');
    let { id } = useParams();

    useEffect(() => {
        const getSingleEvent = async () => {
            try {
                let response = await getEvent(id,true);
                if(response.status === 200) {
                    setSelectedEvent(response.data);
                } else {
                    console.log('An error occured.',response.status)
                }
            } catch(err) {
                console.log(err);
            }
        }

        if(!selectedEvent) {
            getSingleEvent();
        }
    },[]);


    return (
        <section id="attendees" className="scroll-page">
            <div>
                {
                    selectedEvent
                    ? <> 
                        <h1 className="section-heading"><span>Attendees for {selectedEvent.title}</span></h1>
                        {
                            getGender() === 'F'
                            ? <> {
                                selectedEvent.male_attendees.map((attendee) => (
                                    <div key={attendee.id}>
                                        <AttendeeListItem attendee={attendee} event={selectedEvent}/>
                                    </div>
                                ))
                            } </>
                            : <> {
                                selectedEvent.female_attendees.map((attendee) => (
                                    <div key={attendee.id}>
                                        <AttendeeListItem attendee={attendee} event={selectedEvent}/>
                                    </div>
                                ))
                            } </>
                        }
                        <AttendeesActionBtn event={selectedEvent} setSubmitMessage={setSubmitMessage} />
                        <p className="error">{submitMessage}</p>
                    </>
                    : <LoadingSpinner />
    
                }
            </div>
        </section>


    );
}


export default Attendees;