import {useNavigate} from "react-router-dom"
import {useState, useContext} from 'react';
import {resetPassword} from '../network/auth';
import {Context} from '../context/StorageContext';

const ForgotForm = () => {
    const navigate = useNavigate();
    const {setLoggedIn} = useContext(Context);

    const [email,setEmail] = useState('');
    const [emailErrorClass, setEmailErrorClass] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [formErrors, setFormErrors] = useState([]);

    const validateForm = () => {
        //clear
        setEmailErrorClass('');
        let valid = true;
        if(email.trim() === '') {
            setFormErrors(['You must enter a value.',]);
            setEmailErrorClass('formErrorInput');
            document.querySelector('#email').focus();
            valid = false
        }
        return valid;
    }

    const handleReset = async (e) => {
        e.preventDefault();
        const payload = {
            email
        }
        if(validateForm()) {
            const result = await resetPassword(payload);
            if(result.status == 200) {
                setSuccessMessage('A password reset link has been sent to your registered email address. Please click the link to reset your password.')
            } else {
                setFormErrors(['Sorry an error occurred.']);
            }
        }
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    }

    return(
        <>
            {
                <form id="signInForm" className="userForm" onSubmit={handleReset} >
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input id="email" className={emailErrorClass} type="email" onChange={handleChangeEmail} />
                    </div>
                    <div className="form-submit">
                        <input type="submit" value="Reset" />
                    </div>
                    <div className="form-errors">
                        <ul>
                            {
                                formErrors.map(e => (
                                    <li key={e}>{e}</li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="success-message-container"><p>{successMessage}</p></div>
                </form>
            }
        </>

    );
}

export default ForgotForm;