import { useContext } from "react";
import { Context } from "../context/StorageContext";
import MessageModal from './modals/MessageModal';

const MessagePanel = (props) => {
    const {setMessageModalOptions,state:{messageModalOptions,selectedMessage}} = useContext(Context);

    const handleReply = () => {
        setMessageModalOptions({
            ...messageModalOptions,
            show:true,
            to: { id:props.message.from_user.id, name:props.message.from_user.name},
            subject: 'Re: ' + props.message.subject,
        });
    }

    return(
        props.message
        ? <div className="message-panel">
            <div className="modal-container">
                <MessageModal 
                    show={messageModalOptions.show} 
                    to={{ id:props.message.from_user.id, name:props.message.from_user.name}}
                    subject={'Re: ' + props.message.subject}
                    message="" 
                />
            </div>
            {
                props.received
                ? <div className="list-item">
                    <p><strong>From:</strong></p><p>{props.message.from_user.name}</p>
                </div>
                : <div className="list-item">
                    <p><strong>To:</strong></p><p>{props.message.to_user.name}</p>
                </div>
            }
            <div className="list-item">
                <p><strong>Date:</strong></p><p>{props.message.sent_date_local}</p>
            </div>
            <div className="list-item">
                <p><strong>subject:</strong></p><p>{props.message.subject}</p>
            </div>
            <p>
                {props.message.message}
            </p>
            {
                props.received
                ? <button type="button" className="flat-button" onClick={handleReply}>Reply</button>
                : null
            }
        </div>
        : <div className="no-message-container"><p>There is no message to display.</p></div>
    );
}

export default MessagePanel;