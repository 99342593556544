import { useContext } from 'react';
import {Context} from '../context/StorageContext';
import portrait from '../assets/img/portrait.png';

const AttendeeListItem = (props) => {
    const {setLike} = useContext(Context);

    const handleChangeLike = e => {
        setLike({
            id: props.attendee.id,
            like: e.target.checked,
        });
    }

    return(
        <>
        {
            props.attendee.profile
            ? <div className="attendee-list-item-profile">
                <div className="img-container"><img src={props.attendee.profile.picture.image} alt={props.attendee.name} /> </div>
                <p>{props.attendee.name}</p>
                <p>{props.attendee.profile.headline}</p>
                <> {
                    props.event.past_event
                    ? <>{
                        props.event.likes_submitted
                        ? <>{
                            props.event.likes.filter(like => like.like.id === props.attendee.id).length > 0
                            ? <label><input type="checkbox" disabled={true} checked={true}/> like</label>
                            :<label><input type="checkbox" disabled={true} checked={false}/> like</label>
                        }</> 
                        : <label><input type="checkbox" onChange={handleChangeLike}/> like</label>
                    }</>
                    : null
                } </>
            </div>
            : <div className="attendee-list-item">
                <div className="img-container"><img src={portrait}  alt={props.attendee.name} /></div>
                <p>{props.attendee.name}</p>
                <p>This person does not have a profile</p>
                <> {
                    props.event.past_event
                    ? <>{
                        props.event.likes_submitted
                        ? <>{
                            props.event.likes.filter(like => like.like.id === props.attendee.id).length > 0
                            ? <label><input type="checkbox" disabled={true} checked={true}/> like</label>
                            :<label><input type="checkbox" disabled={true} checked={false}/> like</label>
                        }</> 
                        : <label><input type="checkbox" onChange={handleChangeLike}/> like</label>
                    }</>
                    : null
                } </>
            </div>   
        }
        </>
    );
}

export default AttendeeListItem;