import {conn, connWithToken, multipartConn} from './api';


const getProfile = () => {
    return new Promise((resolve,reject) => {
        connWithToken.post('/api/getprofile/')
        .then( (response) => {
          return resolve(response.data);
        }).catch((err) => {
          return reject(err.response.data);
        });
    });
  }


  const createProfile = (payload) => {
    return new Promise((resolve,reject) => {
        multipartConn.post('/accounts/createprofile/', payload)
        .then( (response) => {
          return resolve(response.data);
        }).catch((err) => {
          return reject(err.response.data);
        });
    });
  }

  const updateProfile = (payload) => {
    return new Promise((resolve,reject) => {
        multipartConn.patch('/api/updateprofile/', payload)
        .then( (response) => {
          return resolve(response.data);
        }).catch((err) => {
          return reject(err.response.data);
        });
    });
  }


  const getEvents = (authed) => {
    return new Promise((resolve,reject) => {
        if(authed) {
          connWithToken.post('/api/events/?option=future')
          .then( (response) => {
            return resolve(response);
          }).catch((err) => {
            return reject(err.response);
          });
        } else {
          conn.get('/api/events?option=future')
          .then( (response) => {
            return resolve(response);
          }).catch((err) => {
            return reject(err.response);
          });
        }

    });
  }


  const getMyEvents = (authed) => {
    return new Promise((resolve,reject) => {
      connWithToken.post('/api/myevents/')
      .then( (response) => {
        return resolve(response);
      }).catch((err) => {
        return reject(err.response);
      });
    });
  }
        


  const getEvent = (payload,authed) => {
    return new Promise((resolve,reject) => {
        if(authed) {
          connWithToken.post(`/api/event?id=${payload}`)
          .then( (response) => {
            return resolve(response);
          }).catch((err) => {
            return reject(err.response);
          });
        } else {
          conn.get(`/api/event?id=${payload}`)
          .then( (response) => {
            return resolve(response);
          }).catch((err) => {
            return reject(err.response);
          });
        }
    });
  }


  const createPaymentIntent = (payload) => {
    return new Promise( async (resolve,reject) => {
        await connWithToken.post('/api/orders/createpaymentintent/',payload).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return resolve(err.response);
        });
    });
 }


const submitLikes = (payload) => {
  return new Promise( async (resolve,reject) => {
      await connWithToken.post('/api/sendlikes/',payload).then((res) => {
          return resolve(res);
      }).catch((err) => {
          return resolve(err.response);
      });
  });
}


const getMatches = (payload) => {
  return new Promise( async (resolve,reject) => {
      await connWithToken.post('/api/mymatches/',payload).then((res) => {
          return resolve(res);
      }).catch((err) => {
          return resolve(err.response);
      });
  });
}


const getLikes = (payload) => {
  return new Promise( async (resolve,reject) => {
      await connWithToken.post('/api/mylikes/',payload).then((res) => {
          return resolve(res);
      }).catch((err) => {
          return resolve(err.response);
      });
  });
}



const sendMessage = (payload) => {
  return new Promise( async (resolve,reject) => {
      await connWithToken.post('/api/messaging/sendmessage/',payload).then((res) => {
          return resolve(res);
      }).catch((err) => {
          return resolve(err.response);
      });
  });
}


const getMessages = () => {
  return new Promise( async (resolve,reject) => {
      await connWithToken.post('/api/messaging/mymessages/').then((res) => {
          return resolve(res);
      }).catch((err) => {
          return resolve(err.response);
      });
  });
}

const getNextEvent = () => {
  return new Promise( async (resolve,reject) => {
      await conn.get('/api/nextevent/').then((res) => {
          return resolve(res);
      }).catch((err) => {
          return resolve(err.response);
      });
  });
}


const getUserProfile = (id) => {
  return new Promise( async (resolve,reject) => {
      await connWithToken.post(`/api/getuserprofile/${id}/`).then((res) => {
          return resolve(res);
      }).catch((err) => {
          return resolve(err.response);
      });
  });
}

export {getProfile, createProfile, updateProfile, getEvents, getMyEvents, getEvent,
    createPaymentIntent, submitLikes, getLikes,getMatches, sendMessage, getMessages,
    getNextEvent, getUserProfile};