const LoadingSpinner = () => {

    return(
        <div className="loading">
            <p>Loading...</p>
            <div className="lds-dual-ring"></div>
        </div>  
    );

}

export default LoadingSpinner;