import {useContext} from 'react';
import {Context} from '../context/StorageContext';
import { useNavigate } from 'react-router-dom';
import MessageModal from './modals/MessageModal';

const ProvileView = (props) => {
    const {setEditMode, setMessageModalOptions, state:{profile,messageModalOptions}} = useContext(Context);
    const navigate = useNavigate();

    const handleSendMessage = () => {
        setMessageModalOptions({...messageModalOptions,show:true,messageTo:{id:profile.user.id,name:profile.user.name}});
    }

    return(
        <div id="profile-view">
            <div className="modal-container">
                <MessageModal 
                    show={messageModalOptions.show} 
                    to={messageModalOptions.messageTo}
                    subject="" 
                    message="" 
                />
            </div>
            <div>
                {
                    props.other
                    ? <>
                        <h1 className="section-heading"><span>{profile.user.name}</span></h1>
                        <p className="center-text">Profile for {profile.user.name}.</p>
                    </>
                    : <> 
                        <h1 className="section-heading"><span>Welcome {profile.user.name}</span></h1>
                        <p className="center-text">Here is your profile information.</p>
                    </>
                }   
            </div>
            <div className="profile-grid" >
                <div className="card">
                    <div className="card-title">
                        <p>Bio</p>
                    </div>
                    <div className="card-body">
                        <h2>{profile.headline}</h2>
                        <p>{profile.body}</p>
                    </div>
   
                    <div className="btn-group contained-buttons"> {
                            
                            props.other
                            ? <>
                                <button className="flat-btn-sm" type="button" onClick={() => handleSendMessage()}>Send Message</button>
                            </>
                            : <>
                                <button className="flat-btn-sm" type="button" onClick={() => navigate('/messages')}>My Messages</button>
                                <button className="flat-btn-sm" type="button" onClick={() => navigate('/myevents')}>My Events</button>
                                <button className="flat-btn-sm" type="button" onClick={() => navigate('/mymatches')}>My Matches</button>
                            </>
                    } </div>
                </div>
                <div className="card">
                    <div className="card-title">
                        {
                            props.other
                            ? <p>Portrait</p>
                            : <p>My Portrait</p>
                        }

                        
                    </div>
                    <div className="card-picture">
                        <img src={profile.picture.image} alt={profile.user.name} />
                    </div>
                </div>
            </div>
            {
                props.other
                ? null
                : <button className="flat-button" onClick={ () => setEditMode(true) }>Edit Profile</button>
            }
        </div>
    );

}


export default ProvileView;