
const TermsConditionsModal = (props) => {

    if(props.show) {
        document.body.classList.add('modal-overlay');
    } else {
        document.body.classList.remove('modal-overlay');
    }

    return (
        props.show
        ? <div className="modal">
            <h1>Terms and Conditions</h1>
            <div className="spacer-10"></div>
            <div className="modal-scroll">
                <div className="info-text">
                    <p><strong>Daterados &copy; { new Date().getFullYear() } is a social event, speed dating site with the purpose of hosting
                        speed dating events. By browsing the site and / or making purchases, you are agreeing to the terms and conditions listed below.</strong></p>
                </div>
                <h2>Refund Policies</h2>
                <div className="info-text">
                    <p>We are unable to offer refunds once you have booked. We suggest finding someone else to replace you, should you decide not to go.</p>
                </div>
                <h2>Payments</h2>
                <div className="info-text">
                    <p>We accept payments on our online store by credit card only. Payments are handled by a third party provider 
                        (see privacy policy).</p>
                </div>
                <h2>Data and Privacy Policy</h2>
                <div className="info-text">
                    <ul>
                        <li>Credit card payments are handled by a third party. We do not store, have access to or process any of your credit card information. 
                            This is purely handled securely by a third party provider.</li>
                        <li>We collect and store personal data, purely for the purpose of fulfilling orders and having contact information should we need to notify you on the status of your order. 
                            None of the information we collect is shared with third-parties. We do not use data for tracking purposes, promotions or sales.</li>
                        <li>Your gender is taken during registration and stored in the database, this is so we can organise the Speed Dating events which are predominantly
                            members taking part in five minute dates with members of the opposite sex.
                        </li>
                        <h3>Registration</h3>
                        <li>Registration is free, the only charges are for booking events.</li>
                        <li>Purchase of our products requires registration. Registration requires the use of a valid email address which we store
                            in our database for identification purposes and to inform you of your purchases.
                        </li>
                        <li>We will inform you of a data breach, should one occur.</li>
                        <li>You have the right for any personal data to be removed from our systems at any time. This can be requested by contacting us directly.</li>
                    </ul>
                </div>
                <h2>Use of Website and Forum</h2>
                <div className="info-text">
                    <p>The website allows messages to be posted to other users and the creation of a user biography. By registring you are agreeing to the following rules:</p>
                    <ul>
                        <li>No Spam, Advertising, Self-promote.</li> 
                        <li>Do not post copyright-infringing material. Providing or asking for information on how to illegally obtain copyrighted materials is forbidden.</li>
                        <li>Do not post “offensive” posts, links or images. Any material which constitutes defamation, harassment, or abuse is strictly prohibited. Material that is sexually or otherwise obscene, racist, or otherwise overly discriminatory is not permitted on this site.</li> 
                        <li>Remain respectful of other members at all times</li>
                        <li>All posts should be professional and courteous. You have every right to disagree with your fellow community members and explain your perspective. DO NOT ASK for email addresses or phone numbers.</li>
                    </ul>
                    <p><strong>Any opinions posted by other users are solely he opinion of the user and are in no way the opinion of the website author.</strong></p>
                    <p>Daterados reserves the right to delete any content deemed inappropriate at any time without warning</p>
                </div>
                <h2>Cookies</h2>
                <div className="info-text">
                    <p>There is no use of cookies on this site. Third party cookies are not used.</p>
                </div>
            </div>
            <p>{props.message}</p>
            <div className="btn-group">
                <button type="button" className="flat-button" onClick={props.closeAction} >Close</button>
            </div>
        </div>
        :null
    );
}

export default TermsConditionsModal;