import {useState, useContext, useRef, useEffect} from 'react';
import {Context} from '../context/StorageContext';
import portrait from '../assets/img/portrait.png';
import { createProfile, updateProfile } from '../network/apiactions';

const ProfileForm = () => {
    const {setProfile,setEditMode,state:{editMode, profile}} = useContext(Context);

    const [headline,setHeadline] = useState('');
    const [body,setBody] = useState('');
    const [headlineErrorClass, setHeadlineErrorClass] = useState('');
    const [bodyErrorClass, setBodyErrorClass] = useState('');
    const [formErrors, setFormErrors] = useState([]);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const fileInput = useRef();
    const imageElement = useRef();

    const validateForm = () => {
        //clear
        setFormErrors([]);
        setHeadlineErrorClass('');
        setBodyErrorClass('');
        let valid = true;
        let errorsList = [];

        if(!imageLoaded && !editMode) {
            setFormErrors([...formErrors,'You need to add a profile picture.',]);
            errorsList = [...errorsList,'You need to add a profile picture.',]
            valid = false;
        }
        if(headline.trim() === '' && !editMode ) {
            setFormErrors([...formErrors,'You need to add a title to your profile.',]);
            errorsList = [...errorsList,'You need to add a title to your profile.',];
            setHeadlineErrorClass('formErrorInput');
            document.querySelector('#headline').focus();
            valid = false
        }
        if(body.trim() === '' && !editMode) {
            setFormErrors([...formErrors,'Please enter your profile information.',]);
            errorsList = [...errorsList,'Please enter your profile information.',];
            setBodyErrorClass('formErrorInput');
            document.querySelector('#body').focus();
            valid = false
        }
        setFormErrors(errorsList);
        return valid;
    }

    const chooseFile = () => {
        document.querySelector('#profile-picture').click();
    }

    function handleAddImage() {
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            
        let file = fileInput.current.files[0];
        let reader  = new FileReader();

        if(file.size >= 2*1024*1024) {
            setFormErrors(['Sorry, your portrait picutre needs to be less than 2Mb.']);
            return;
        }

        if(file.type.split('/')[0] === 'image') {
            reader.onload = function(e)  {
                // the result image data
                imageElement.current.src = e.target.result;
                setImageLoaded(true);
            }
            // you have to declare the file loading
            reader.readAsDataURL(file);
        }

    }

    const handleCreateProfile = async (e) => {
        e.preventDefault();
        if(validateForm()) {
            let payload = new FormData();
            //Conditon where edit mode exists and thre is a file to add
            if(fileInput.current.files.length > 0) {
                payload.append('picture',fileInput.current.files[0]);
            } 
            payload.append('body', body);
            payload.append('headline', headline);
            setLoading(true);
            let success;
            try {
                if(editMode) {
                    success = await updateProfile(payload);
                    setEditMode(false);
                } else {
                    success = await createProfile(payload);
                }
            } catch(err) {
                setFormErrors(['An error occured trying to create the profile.']);
            }
            setLoading(false);
            setProfile(success);
            
        }
    }

    return(

        <>
            <h1 className="section-heading"><span>Edit Profile</span></h1>
            {
                <form id="profile-form" className="userForm" onSubmit={e => handleCreateProfile(e)} >
                    <div className="profile-form-container">
                        <div className="form-col card">
                            <div className="card-title">
                                <p>Bio</p>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Headline</label>
                                <small>Describe your self in a few words.</small>
                                {
                                    editMode
                                    ? <input id="headline" className={headlineErrorClass} type="text" onChange={(e) => setHeadline(e.target.value)} defaultValue={profile.headline} />
                                    : <input id="headline" className={headlineErrorClass} type="text" onChange={(e) => setHeadline(e.target.value)} />
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Profile text:</label>
                                <small>Write about yourself, for example, what do you enjoy doing?</small>
                                {
                                    editMode 
                                    ? <textarea id="body" className={bodyErrorClass} rows="6" onChange={(e) => setBody(e.target.value)} defaultValue={profile.body} ></textarea>
                                    : <textarea id="body" className={bodyErrorClass} rows="6" onChange={(e) => setBody(e.target.value)} ></textarea>
                                }
                            </div>
                        </div>
                        <div className="form-col card">
                            <div className="card-title">
                                <p>Portrait</p>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                <small className="center-text">Please upload a profile image.</small>
                                <div className="profile-image-display">
                                    { 
                                        editMode
                                        ? <img id="image-preview" ref={imageElement} src={profile.picture.image}  alt="image placeholder" className="portrait-image" />
                                        : <img id="image-preview" ref={imageElement} src={portrait}  alt="image placeholder" className="portrait-image" />
                                    }
                                    
                                </div>
                                <input type="file" ref={fileInput} id="profile-picture" name="profile-picture" onChange={ handleAddImage }/>
                                <button type="button" className="flat-btn-sm" onClick={ chooseFile }>Choose Picture</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-submit">
                        {
                            loading
                            ? null
                            : <input type="submit" value="Save" />
                        }
                    </div>
                    <div className="form-errors">
                        <ul>
                            {
                                formErrors.map((e,i) => (
                                    <li key={i}>{e}</li>
                                ))
                            }
                        </ul>
                    </div>
                </form>
            }
        </>

    );
}

export default ProfileForm;