import { useEffect,useContext,useState } from "react";
import { Context } from "../context/StorageContext";
import { getLikes,getMatches } from "../network/apiactions";
import { useNavigate } from "react-router-dom";
import MessageModal from '../components/modals/MessageModal';
import MatchListItem from '../components/MatchListItem';
import LoadingSpinner from "../components/LoadingSpinner";

const Matches = () => {
    const {setMyMatches,setMyLikes,setErrorScreenMessage,setMessageModalOptions,state:{myLikeList,myMatchList,messageModalOptions,errorMessage,successMessage}} = useContext(Context);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const getMatchesAndLikes = async () => {
            try {
                let matches = await getMatches();
                if(matches.status === 200) {
                    setMyMatches(matches.data.matches);
                } else {
                    setErrorScreenMessage('There was an error retrieving the matches. Please try again later.');
                    navigate('/errorscreen');
                }
            } catch(err) {
                setErrorScreenMessage('There was an error retrieving the matches. Please try again later.');
                navigate('/errorscreen');
            }

            try {
                let likes = await getLikes();
                if(likes.status === 200) {
                    setMyLikes(likes.data.likes);
                    setLoading(false);
                } else {
                    setErrorScreenMessage('There was an error retrieving the likes. Please try again later.');
                    navigate('/errorscreen');
                }
            } catch(err) {
                setErrorScreenMessage('There was an error retrieving the likes. Please try again later.');
                navigate('/errorscreen');
            }
        }

        getMatchesAndLikes();
    },[]);

    return(
        <section id="matches" className="scroll-page">
            {
                loading
                ? <LoadingSpinner />
                : <>
                    <div className="modal-container">
                        <MessageModal 
                            show={messageModalOptions.show} 
                            to={messageModalOptions.messageTo}
                            subject="" 
                            message="" 
                        />
                    </div>
                    <div className="match-container">
                        <h1 className="section-heading"><span>My Matches</span></h1>
                        <p className="center-text">These are people you have matched with. You can message them to arrange a date.</p>
                        {
                            myMatchList.length > 0
                            ? <div className="match-container">
                                {
                                    myMatchList.map((match) => (
                                        
                                        <MatchListItem key={match.id} profile={match} />
                                    ))
                                }
                            </div>
                            : <p className="center-text">You haven't matched with anyone yet. Attend more events to get more matches. When you match with people you can message them.</p>
                        }
                        <h1 className="section-heading"><span>My Likes</span></h1>
                        <p className="center-text">These are people who like you, but you haven't matched with them.</p>
                        {
                            myLikeList.length > 0
                            ? <div className="like-container">
                                {
                                    myLikeList.map((like) => (
                                        <MatchListItem key={like.id} profile={like} />
                                    ))
                                }
                            </div>
                            : <p className="center-text">No one likes you yet! Don't worry, the more events you attend the more people will like you.</p>
                        }
                    </div>
                    <div className="error-message-container"><p>{errorMessage}</p></div>
                    <div className="success-message-container"><p>{successMessage}</p></div>
                </>
            }

        </section>
    );
}

export default Matches;