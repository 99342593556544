const MessageListItem = (props) => {
    return(
        <>
            {
                props.received
                ? <p>{props.message.from_user.name}</p>
                : <p>{props.message.to_user.name}</p>
            }
            <p>{props.message.subject}</p>
            <p>{props.message.sent_date_local}</p>
        </>
    );
}

export default MessageListItem;