import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { getProfile } from '../network/apiactions'
import ProfileForm from "../forms/profile_form";
import ProfileView from '../components/ProfileView';
import {Context} from '../context/StorageContext';
import LoadingSpinner from "../components/LoadingSpinner";

const Profile = () => {
    const navigate = useNavigate();
    const [waiting, setWaiting] = useState(true);
    const {setProfile, state:{profile, editMode}} = useContext(Context);

    useEffect(() => {
        const loadProfile = async () => {
            try {
                let data = await getProfile();
                setProfile(data);
                setWaiting(false);
            } catch(err) {
                if(err) {
                    if(err.message === 'Failed to find profile profile.' ) {
                        //Set up so the user can add a profile
                        console.log('Profile doesn\t exist');
                    }
                    setProfile(null);
                    setWaiting(false);
                } else {
                    navigate('/error500');
                }
            }
        }

        loadProfile();
    }, []);

    return (
        <section id="profile" className="one-page">
            <div className="one-page-content">
                {
                    waiting
                    ? <LoadingSpinner />
                    : <> {   
                        profile && !editMode
                        ? <ProfileView other={false} />
                        : <ProfileForm />
                    } </>
                }   
            </div>
            <div className="spacer-10"></div>
        </section>
    );
}

export default Profile;