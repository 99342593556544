import createDataContext from './createDataContext';

const defaultState = {
  loggedIn: false,
  profile: {},
  editMode: false,
  eventList: [],
  selectedEvent: null,
  errorScreenMessage: '',
  likeList : [],
  //Below are whre liked by another person
  myLikeList : [],
  myMatchList : [],
  messageModalOptions: {
    show : false,
    message : '',
    subject : '',
    to : null
  },
  messages : {
    from_messages:[],
    to_messages:[]
  },
  errorMessage : '',
  successMessage : '',
  selectedMessage : null,
};

const storageReducer = (state,action) => {
  switch(action.type) {
    case 'setLoggedIn':
      return {...state,loggedIn:action.payload};
    case 'setProfile':
        return {...state,profile:action.payload};
    case 'setEditMode':
        return {...state,editMode:action.payload};
    case 'setEventList':
      return {...state,eventList:action.payload};
    case 'setSelectedEvent':
        return {...state,selectedEvent:action.payload};
    case 'setErrorScreenMessage':
          return {...state,errorScreenMessage:action.payload};
    case 'setLike':
        //Remove existing if there
        let newLikeList = state.likeList.filter(e => e.id !== action.payload.id);
        return {...state,likeList:[...newLikeList, action.payload]};
    case 'setMyLikes':
      return {...state,myLikeList:action.payload};
    case 'setMyMatches':
      return {...state,myMatchList:action.payload};
    case 'setMessageModalOptions':
      return {...state,messageModalOptions:action.payload};
    case 'setSuccessMessage':
      return {...state,successMessage:action.payload};
    case 'setErrorMessage':
      return {...state,errorMessage:action.payload};
    case 'setMessages':
      return {...state,messages:action.payload};
    case 'setSelectedMessage':
      return {...state,selectedMessage:action.payload};
    default:
      return defaultState;
  }
};

const setLoggedIn = (dispatch) => async () => {
  dispatch({type:'setLoggedIn', payload:true});
}

const setProfile = (dispatch) => async (profile) => {
    dispatch({type:'setProfile', payload:profile});
  }


const setEditMode = (dispatch) => async (mode) => {
    dispatch({type:'setEditMode', payload:mode});
}

const setEventList = (dispatch) => async (events) => {
  dispatch({type:'setEventList', payload:events});
}

const setSelectedEvent = (dispatch) => async (event) => {
  dispatch({type:'setSelectedEvent', payload:event});
}

const setErrorScreenMessage = (dispatch) => async (message) => {
  dispatch({type:'setErrorScreenMessage', payload:message});
}

const setLike = (dispatch) => async (likeObj) => {
  dispatch({type:'setLike', payload:likeObj});
}

const setMyLikes = (dispatch) => async (likes) => {
  dispatch({type:'setMyLikes', payload:likes});
}

const setMyMatches = (dispatch) => async (matches) => {
  dispatch({type:'setMyMatches', payload:matches});
}

const setMessageModalOptions = (dispatch) => async (options) => {
  dispatch({type:'setMessageModalOptions', payload:options});
}

const setErrorMessage= (dispatch) => async (message) => {
  dispatch({type:'setErrorMessage', payload:message});
}

const setSuccessMessage = (dispatch) => async (message) => {
  dispatch({type:'setSuccessMessage', payload:message});
}

const setMessages = (dispatch) => async (messages) => {
  dispatch({type:'setMessages', payload:messages});
}

const setSelectedMessage = (dispatch) => async (message) => {
  dispatch({type:'setSelectedMessage', payload:message});
}


export const {Provider, Context} = createDataContext (
  storageReducer,
  { setLoggedIn, setProfile, setEditMode, setEventList, setSelectedEvent, setErrorScreenMessage, setLike,
  setMyLikes, setMyMatches, setMessageModalOptions, setErrorMessage, setSuccessMessage, setMessages,
  setSelectedMessage },
  {...defaultState}
);