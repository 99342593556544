import {useState, useEffect} from 'react';
import SignInForm from '../forms/signin_form';
import ForgotForm from '../forms/forgot_form';
import {checkAuthed} from '../network/auth';

const SignIn = () => {
    const [isAuthed, setIsAuthed] = useState(false);
    const [forgotMode, setForgotMode] = useState(false);

    useEffect(() => {
        const checkAuthentication = async () => {
            let authed = await checkAuthed();
            if(authed) {
                setIsAuthed(true);
            };
        }
        checkAuthentication();
    },[]);

    const handleForgot = (e) => {
        e.preventDefault();
        setForgotMode(!forgotMode);
    }

    return(
        <>
            <section id="signin" className="one-page">
                <div className="one-page-content">
                    {
                        !isAuthed
                        ?
                            <>
                                <h1 className="section-heading"><span>Sign In</span></h1>
                                {
                                    !forgotMode
                                    ? <div className="form-container panel">
                                        <SignInForm />
                                        <p><small>Forgot your password? Click <a href="" onClick={(e) => handleForgot(e)}>here</a> to reset it.</small></p>
                                    </div>
                                    : <div className="form-container panel">
                                        <ForgotForm />
                                        <p><small>Can you remember your password? Click <a href="" onClick={(e) => handleForgot(e)}>here</a> to sign in.</small></p>
                                    </div>
                                }
                            </>


                        :<div><h1 className="section-heading"><span>You are signed in.</span></h1></div>
                    }
                </div>
            </section>

        </>

    );
}

export default SignIn;