import {useEffect,useState} from 'react';
import { Navigate } from "react-router-dom";
import {checkAuthed} from '../network/auth';

export const ProtectedRoute = ({ children }) => {
    const [authed,setAuthed] = useState(true);

    useEffect(() => {
        checkAuthed().then((response) => {
            if(response) {
                setAuthed(true);
            } else {
                setAuthed(false);
            }
        });
    },[]);

    if(!authed) {
        return <Navigate to="/signin" />;
    } else {
        return children;
    }
};

export default ProtectedRoute;