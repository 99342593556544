import React, { useState, useEffect, useContext } from 'react';
import { createPaymentIntent, getEvent } from '../../network/apiactions';
import { checkAuthed } from '../../network/auth';
import {useNavigate, useParams} from "react-router-dom";
import {Context} from '../../context/StorageContext';
import CheckoutForm from '../../forms/checkout_form';
import LoadingSpinner from '../../components/LoadingSpinner';


const Checkout = () => {
  const [isAuthed, setIsAuthed] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const navigate = useNavigate();
  const {setSelectedEvent,setErrorScreenMessage,state:{selectedEvent,loggedIn}} = useContext(Context);
  let { id } = useParams();


  useEffect(() => {

    //Need to create payment intent
    const getPaymentIntent = async (id) => {
        let paymentIntent = await createPaymentIntent({event_id: id});
        switch (paymentIntent.status) {
          case 401:
            navigate('/signin');
            break;
          case 200:
            setClientSecret(paymentIntent.data.payment_intent.client_secret)
            setPaymentIntentId(paymentIntent.data.payment_intent.intent_id);
            break;
          default:
            setErrorScreenMessage('There was an error connecting to the payment gateway. Please try again later.');
            navigate('/errorscreen');
        }
    }

    const getSingleEvent = async () => {
        try {
            let response = await getEvent(id);
            if(response.status === 200) {
                setSelectedEvent(response.data);
                getPaymentIntent(response.data.id);
            } else {
                console.log('An error occured.',response.status)
            }
        } catch(err) {
            console.log(err);
        }
        
    }

    const processPaymentIntent = () => {
        if(!selectedEvent) {
            getSingleEvent();
        } else {
            getPaymentIntent(selectedEvent.id);
        }
    }

    const checkAuthentication = async () => {
        let authed = await checkAuthed();
        if(authed) {
            setIsAuthed(true);
            processPaymentIntent();
        }
    }

    if(loggedIn) {
        setIsAuthed(true);
        processPaymentIntent();
    } else {
        checkAuthentication();
    }
  }, []);



  return (
    <section id="checkout" className="one-page"> 
     <h1 className='section-heading'><span>Total to Pay : £{selectedEvent.price}</span></h1>
        {
            isAuthed
            ? <div className="form-container panel"> {
                selectedEvent && clientSecret
                ? <CheckoutForm clientSecret={clientSecret} totalToPay={selectedEvent.price} />
                : <LoadingSpinner />
            } </div> 
            : <div className="one-page-content"><p className="center-text"><strong>You are not authorised, please sign in before proceding with payment.</strong></p></div>
        }
    </section> 
  );
}

export default Checkout;