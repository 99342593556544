import { Routes, Route} from "react-router-dom";
import './App.css';
import Layout from './layout/layout';
import SignIn from "./screens/signin";
import Register from "./screens/Register";
import Signout from "./screens/Signout";
import Home from "./screens/Home";
import Events from "./screens/Events";
import MyEvents from "./screens/MyEvents";
import Event from './screens/Event';
import Attendees from "./screens/Attendees";
import ErrorScreen from "./errors/ErrorScreen";
import Error500 from './errors/Error500';
import Profile from "./screens/Profile";
import Matches from './screens/Matches';
import Messages from './screens/Messages';
import ProtectedRoute from './layout/ProtectedRoute';
import Checkout from './screens/checkout/checkout';
import CheckoutSuccess from "./screens/checkout/success";
import OtherProfile from './screens/OtherProfile';

function App() {
  return (
    <div>
      <Routes>
          <Route path="/" element={<Layout />}>
              <Route index element={<Home/>} />
              <Route path="/events" element={<Events />}/>
              <Route path="/signin" element={<SignIn />}/>
              <Route path="/register" element={<Register />}/>
              <Route path="/home" element={<Home />}/>
              <Route path="/event/:id" element={<Event />}/>
              <Route path="/myevents" element={<ProtectedRoute><MyEvents /></ProtectedRoute>}/>
              <Route path="/messages" element={<ProtectedRoute><Messages /></ProtectedRoute>}/>
              <Route path="/mymatches" element={<ProtectedRoute><Matches /></ProtectedRoute>}/>
              <Route path="/attendees/:id" element={<ProtectedRoute><Attendees /></ProtectedRoute>}/>
              <Route path="/checkout/:id" element={<Checkout />}/>
              <Route path="/userprofile/:id" element={<OtherProfile />}/>
              <Route path="/success" element={<CheckoutSuccess />}/>
              <Route path="/signout" element={ <Signout /> } />
              <Route path="/errorscreen" element={ <ErrorScreen /> } />
              <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>}/>
              {/* Error Routes  */}
              <Route path="/error500" element={<Error500 />} />
          </Route>
      </Routes>
    </div>
  );
}

export default App;
