import { useEffect, useContext, useState } from 'react'; 
import { Context } from '../context/StorageContext';
import { getMessages } from '../network/apiactions';
import MessageListItem from '../components/MessageListItem';
import MessagePanel from '../components/MessagePanel';

const Messages = () => {
    const [showInbox,setShowInbox] = useState(true); 
    const {setMessages,setErrorMessage,setSelectedMessage,state:{messages,errorMessage,selectedMessage}} = useContext(Context);

    useEffect(() => {
        const getMyMessages = async () => {
            setErrorMessage('');
            try{
                let response = await getMessages();
                if(response.status == 200) {
                    setMessages(response.data);
                    //Set the first message from the inbox
                    if(response.data.to_messages.length > 0) {
                        setSelectedMessage(response.data.to_messages[0]);
                    } else {
                        setSelectedMessage(null);
                    }
                } else {
                    setErrorMessage('A problem occured retrieving your messages.');
                }
            } catch(err) {
                setErrorMessage('A problem occured retrieving your messages.');
            }
        }
        getMyMessages();
    },[]);

    const handleDisplayMessage = (id) => {
        let message;
        if(showInbox) {
            message = messages.to_messages.filter(m => m.id === id);
        } else {
            message = messages.from_messages.filter(m => m.id === id);
        }
        setSelectedMessage(message[0]);
    }

    const handleSwitchSentReceived = (show) => {
        if(showInbox) {
            if(messages.from_messages.length > 0) {
                setSelectedMessage(messages.from_messages[0]);
            } else {
                setSelectedMessage(null);
            }
        } else {
            if(messages.to_messages.length > 0) {
                setSelectedMessage(messages.to_messages[0]);
            } else {
                setSelectedMessage(null);
            }
        }
        setShowInbox(show);
    }

    return(
        <section id="messages-section" className="scroll-page">
            <h1 className="section-heading"><span>Messages</span></h1>
            <div className="pills">
                {
                    showInbox
                    ? <button type="button" className="flat-btn-sm pills-btn pills-selected" onClick={() => handleSwitchSentReceived(true)}>Inbox</button>
                    : <button type="button" className="flat-btn-sm pills-btn" onClick={() => handleSwitchSentReceived(true)}>Inbox</button>
                }
                {
                    showInbox
                    ? <button type="button" className="flat-btn-sm pills-btn" onClick={() => handleSwitchSentReceived(false)}>Sent</button>
                    : <button type="button" className="flat-btn-sm pills-btn pills-selected" onClick={() => handleSwitchSentReceived(false)}>Sent</button>
                }
            </div>
            <div className="messages-container">
                <div className="messages-list-container">
                    <div className="messages-list-item message-list-head">
                        <p>Name</p><p>Subject</p><p>Date</p>
                    </div>
                    {
                        showInbox
                        ? messages.to_messages.map((message) => (
                            <div 
                                key={message.id} 
                                className={"messages-list-item " + (selectedMessage ? selectedMessage.id === message.id ? 'selected-message-item' : '' : '' )  } 
                                onClick={ (id) => handleDisplayMessage(message.id)}
                            >
                                <MessageListItem message={message} selected={selectedMessage} />
                            </div>
                        ))
                        : messages.from_messages.map((message) => (
                            <div 
                                key={message.id} 
                                className={"messages-list-item " + (selectedMessage ? selectedMessage.id === message.id ? 'selected-message-item' : '' : '' )  } 
                                onClick={(id) => handleDisplayMessage(message.id)}
                            >
                                <MessageListItem message={message} selected={selectedMessage}/>
                            </div>
                        ))
                    }
                </div>
                <MessagePanel message={selectedMessage} received={showInbox} />
            </div>
            <div className="error-message-container">
                <p>{errorMessage}</p>
            </div>
        </section>
    ); 
}

export default Messages;