import { useEffect,useState } from "react";
import { getNextEvent } from "../network/apiactions";


const Home = () => {
    const [nextEvent, setNextEvent] = useState(null)

    useEffect(() => {
        const getNextEventForDisplay = async () => {
            let event = await getNextEvent();
            if(event.status === 200) {
                setNextEvent(event.data);
            }
        }

        getNextEventForDisplay();
    },[]);

    return(
        <>
            <section id="about">
                <h1 className="section-heading"><span>Speed Dating</span></h1>
                <h2>Speed dating is fun!</h2>
                <p>Dating can sometimes feel like the Wild West. That's why we are running these events to make it 
                    the experience more fun and enable you to find the right partner.</p>
                <p>Daterados speed dating events run in various locations within the Black Country.</p>
                <p>Simply <a href="/register">sign up</a> for a free account, select the event you wish to attend
                and pay, then you only need to turn up.</p>
                <h2>Gender Balancing</h2>
                <p>We run a gender balancing policy on the heterosexual events. This works by having limited male places 
                    and limited female places. A male place is only released once a female has booked and vice versa. this
                    means that the events have an even number of males and females. 
                </p>
                <p>We can't guarantee everyone will turn up, if not then we will make arrangements to make sure you still have the
                    same experience.
                </p>
            </section>
            <section id="events">
                <h1 className="section-heading"><span>Next Event</span></h1>
                <h2 className="center-text">See our next exciting event below!</h2>
                {
                    nextEvent
                    ?
                    <>
                        <div className="grid-col-3">
                            <div></div>
                            <div className="card">
                                <div className="card-title">
                                    <p>{nextEvent.title}</p>
                                </div>
                                <div className="card-picture">
                                    <img src={nextEvent.venue.picture.image} alt={nextEvent.title} />
                                </div>
                                <div className="card-body">
                                    <h3>{nextEvent.venue.name}</h3>
                                    <h3>{nextEvent.start_date_local}</h3>
                                    <div className="list-item">
                                        <p><strong>Places Left</strong></p>
                                        <p>{nextEvent.places.places_left}</p>
                                    </div>
                                    <div className="list-item">
                                        <p><strong>Price</strong></p>
                                        <p>£ {nextEvent.price}</p>
                                    </div>
                                    <p>{nextEvent.description}</p>
                                    <p>Click <a href={`/event/${nextEvent.id}/`} >here</a> to see more!</p>
                                </div>
                            </div>
                            <div></div>
                        </div>
                        <p className="center-text">See all of our events <a href="/events">here</a>.</p>
                    </>

                    : <p className="center-text">Sorry there are no future events.</p>

                }

            </section>
        </>
    );
}

export default Home;