import {useState} from 'react';
import { useNavigate } from 'react-router';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements
  } from '@stripe/react-stripe-js';


const CheckoutForm = (props) => {
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [cards, setCards] = useState(null);
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const handleCheckout = async () => {
        setProcessing(true);
    
        const payload = await stripe.confirmCardPayment(props.clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement)
          }
        });
    
        if (payload.error) {
          setError(`Payment Failed: ${payload.error.message}`);
          setProcessing(false);
        } else {
          navigate('/success');
        }
      }
    
    
      const cardHandleChange = event => {
        const { error } = event;
        setError(error ? error.message: '');
      }
    
      const cardStyle = {
        style: {
          border: '1px solid #222',
          base: {
            backgroundColor: "#fff",
            color: "#000",
            fontFamily: 'Roboto, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "18px",
            "::placeholder": {
              color: "#606060",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        }
      };
    
      const cardOuterStyle ={
         backgroundColor: "#fff",
         border: '1px solid #222',
         outlineColor: '#7fffd4',
         padding : '4px',
      }
    
      let cardOption;
    
      if (cards) {
        cardOption = cards.map(card => {
          const { card: { brand, last4, exp_month, exp_year } } = card;
          return (
            <option key={card.id} value={card.id}>
              { `${brand}/ **** **** **** ${last4} ${exp_month}/${exp_year}` }
            </option>
          );
        });
        cardOption.unshift(
          <option key='Select a card' value=''>Select A Card</option>
        );
      }

    return(
        <div>
            <div>
            <h2>Enter Payment Details</h2>
                <div className="form-group">
                    <label>Number on Card:</label>
                    <div className='stripe-card' style={cardOuterStyle}>
                        <CardNumberElement 
                        className='card-element'
                        options={cardStyle}
                        onChange={cardHandleChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Expiry:</label>
                    <div className='stripe-card' style={cardOuterStyle}>
                    <CardExpiryElement 
                        className='card-element'
                        options={cardStyle}
                        onChange={cardHandleChange}
                    />
                    </div>
                </div>
                <div className="form-group">
                    <label>Enter CVC (3 digit code from back of card):</label>
                    <div className='stripe-card' style={cardOuterStyle}>
                        <CardCvcElement 
                        className='card-element'
                        options={cardStyle}
                        onChange={cardHandleChange}
                        />
                    </div>
                </div>
                <button
                    disabled={processing}
                    className='flat-button'
                    onClick={() => handleCheckout()}
                >
                    { processing ? 'PROCESSING' : 'PAY' }
                </button>

                {
                error && (<p className='error-message'>{error}</p>)
                }
            </div>

        </div>
    );
}

export default CheckoutForm;