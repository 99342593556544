import { useContext } from 'react';
import {Context} from '../context/StorageContext';

const ErrorScreen = () => {
    const {state:{errorScreenMessage}} = useContext(Context);

    return(
        <section id="signin" className="one-page">
            <div className="one-page-content">
                <h1 className="center-text">{errorScreenMessage}</h1>
            </div>
        </section>
    );
}

export default ErrorScreen;