import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProfile } from '../network/apiactions'
import ProfileView from '../components/ProfileView';
import {Context} from '../context/StorageContext';
import LoadingSpinner from "../components/LoadingSpinner";

const OtherProfile = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [waiting, setWaiting] = useState(true);
    const {setProfile, setErrorScreenMessage, state:{profile}} = useContext(Context);

    useEffect(() => {
        const loadProfile = async () => {
            try {
                let response = await getUserProfile(id);
                switch (response.status) {
                    case 200:
                        setProfile(response.data.profile);
                        setWaiting(false);
                        break;
                    case 403:
                        setErrorScreenMessage('Sorry you are not auhtorised to view this profile.');
                        navigate('/errorscreen');
                        break;
                    default:
                        setErrorScreenMessage('There was an error retrieving the profile. Please try again later.');
                        navigate('/errorscreen');
                        break;
                }
            } catch(err) {
                setErrorScreenMessage('There was an error retrieving the likes. Please try again later.');
                navigate('/errorscreen');
            }
        }
        
        loadProfile();
    }, []);

    return (
        <section id="profile" className="one-page">
            <div className="one-page-content">
                {
                    waiting
                    ? <LoadingSpinner />
                    : <> { profile
                        ? <ProfileView other={true}/>
                        : null
                    } </>
                }   
            </div>
            <div className="spacer-10"></div>
        </section>
    );
}

export default OtherProfile;