import RegistrationForm from '../forms/registration_form';

const Register = () => {
    

    return(
        <section id="registration" className="one-page">
            <div className="one-page-content">
                <h1 className="section-heading"><span>Register</span></h1>
                <div className="form-container panel">
                    <RegistrationForm />
                </div>
            </div>
        </section>
    );
}

export default Register;