import { useState, useContext } from "react";
import { Context } from "../../context/StorageContext";
import { sendMessage } from "../../network/apiactions";


const MessageModal = (props) => {
    const [messageBody, setMessageBody] = useState('');
    const [subject, setSubject] = useState('');
    const [sending, setSending] = useState('');
    const {setMessageModalOptions,setSuccessMessage,setErrorMessage,state:{messageModalOptions,myLikeList}} = useContext(Context);

    if(props.show) {
        document.body.classList.add('modal-overlay');
    } else {
        document.body.classList.remove('modal-overlay');
    }

    const handleSend = async () => {
        setErrorMessage('');
        setSuccessMessage('');
        setSending(true);

        let toId;
        if(!props.to) {
            toId = myLikeList.filter(itm => itm.id === parseInt(document.querySelector('#user-select').value))[0].id;
        } else {
            toId = props.to.id;
        }

        //Set the subject if it exists, but hasn't bee changed
        let payloadSubject;
        if(props.subject.length > 0 && subject === '') {
            payloadSubject = props.subject;
        } else {
            payloadSubject = subject;
        }
        
        let payload = {
            toUserId : toId,
            subject: payloadSubject,
            message : messageBody
        }

        try {
            let success = await sendMessage(payload);
            setSuccessMessage('Successfully sent message.');
            setMessageModalOptions({...messageModalOptions,show:false});
            setSending(false);
            if(!success) {
                setErrorMessage('Unable to send message.');
                setMessageModalOptions({...messageModalOptions,show:false});
                setSending(false);
            }
        } catch(err) {
            setErrorMessage('Unable to send message');
            setMessageModalOptions({...messageModalOptions,show:false});
            setSending(false);
        }
    }

    return (
        props.show
        ? <div className="modal message.modal">
            <h1>Send a Message</h1>
            <> {
                props.subject !== ''
                ? <div className="list-item">
                    <span>Subject: </span>
                    <input type="text" onChange={(e) => setSubject(e.target.value)} defaultValue={props.subject} />
                </div>
                : <div className="list-item">
                    <span>Subject: </span>
                    <input type="text" onChange={(e) => setSubject(e.target.value)} />
                </div>
            } </>
            <> {
                props.to !== null
                ? <div className="list-item">
                    <span>To: </span>
                    <span>{props.to.name}</span>
                </div>
                    
                : <div className="list-item">
                    <span>To: </span>
                    <select id="user-select" onChange={() => console.log('onchange')}>
                        {
                            myLikeList.map(like => (
                                <option value={like.id}>{like.name}</option>
                            ))
                        }
                    </select>
                </div>
            } </>
            <> {
                props.message
                ? <p>{props.message}</p>
                : null
            } </>
            <textarea id="message-body" rows="6" onChange={(e) => setMessageBody(e.target.value)} ></textarea>
            <div className="btn-group">
                {
                    sending
                    ? <button type="button" className="flat-button" disabled={true} >Sending</button>
                    : <button type="button" className="flat-button" onClick={handleSend} >Send</button>
                }
                
                <button type="button" className="flat-button" onClick={() => setMessageModalOptions({...messageModalOptions,show:false})} >Cancel</button>
            </div>
        </div>
        :null
    );
}

export default MessageModal;