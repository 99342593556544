import {useState, useContext, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Context} from '../context/StorageContext';
import {getEvents} from '../network/apiactions';
import { checkAuthed } from '../network/auth';
import LoadingSpinner  from '../components/LoadingSpinner';


const Events = () => {
    const {setEventList,setSelectedEvent,setLoggedIn,state:{eventList,loggedIn}} = useContext(Context);
    const [loaded,setLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getEventList = async (authed) => {
            let response;
            if(authed) {
                response = await getEvents(authed);
                if(response.status === 200) {   
                    setEventList(response.data);
                    setLoaded(true);
                } else {
                    console.log('An error occured');
                }
            } else {
                response = await getEvents(authed);
                if(response.status === 200) {   
                    setEventList(response.data);
                    setLoaded(true);
                } else {
                    console.log('An error occured');
                }
            }
        }
        

        const checkAuthentication = async () => {
            let authed = await checkAuthed();
            if(authed) {
                getEventList(true);
                setLoggedIn(true);
            } else {
                getEventList(false);
            }
        }
    
        if(loggedIn) {
            getEventList(true);
        } else {
            checkAuthentication();
        }
    },[]);


    const handleShowEvent = (e,event) => {
        e.preventDefault();
        let selectedEvent = eventList.filter(el => el.id === event.id);
        setSelectedEvent(selectedEvent[0]);
        navigate(`/event/${event.id}`);
    }

    return(
        <section id="events" className="scroll-page">
            <div>
                <h1 className="section-heading"><span>Events</span></h1>
                <p className="center-text">These are all of our upcomming events.</p>
                {
                    loaded
                    ? 
                            <div id='event-list' className="list-container">
                                <div className='list-grid list-head'>
                                    <p>Event</p>
                                    <p className="hide-mob">Starts on</p>
                                    <p className="hide-mob">Ends at</p>
                                    <p className="hide-mob">Location</p>
                                    <p></p>
                                </div>
                                {
                                    eventList.map((event) => (
                                        <div key={event.id} className='list-grid'>
                                            <p>{event.title}</p>
                                            <p className="hide-mob">{event.start_date_local}</p>
                                            <p className="hide-mob">{event.end_date_local}</p>
                                            <p className="hide-mob">{event.venue.name}</p>
                                            <p><a href={`/event/${event.id}/`} onClick={ (e) => handleShowEvent(e,event)}>See More</a></p>
                                        </div>
                                        
                                    ))
                                }
                                {
                                    loggedIn
                                    ? <button type="button" className="flat-button" onClick={() => navigate('/myevents')} >My Events</button>
                                    : null
                                }
                            </div>
                    : <LoadingSpinner />
                }
            </div>
        </section>

    );
}

export default Events;